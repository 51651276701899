/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useCallback } from 'react'
import {
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	Input,
	Button,
	Text,
	Box,
	Flex,
	IconButton,
	Icon,
	useDisclosure,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
} from '@chakra-ui/react'
import moment from 'moment'
import {
	useLoadActivityListV1,
	useSetActivityRef,
	useUpdateActivityRef,
	useLoadActivityParticipant,
	useLoadGanntChildActivity,
	useExternalGuestsAdd,
	useAddParentActivity,
} from '../../hooks/activity.hooks'
import { getMessageUniqueId, getCurrentDateUTC } from '../../utils/common.util'
import { BiArrowBack } from 'react-icons/bi'
import ReferenceList from '../workspace-panel/reference-files/reference-list'
import SetParantActivityChd from './SetParantActivityChd'
import { useAuthContext } from '../../context/auth.context'
import { useLanguageContext } from '../../context/locale.context'
import { useSharedContext } from '../../context/shared.context'
import ParticipantView from '../../containers/workspace-panel/team-collaboration/participant-view'
import AddExtParticipant from '../meetings/add-meetings/events-form/add-participant'
import { sharedConst } from '../../utils/action.constant'
import OriginFormPanel from '../form-panel/form-panel'
import WorkspacePanel from '../workspace-panel/workspace-panel'
import { getDifferenceInDays } from './../../utils/date.utils'
import {
	GA_CATEGORY_GANTT,
	sectionDetailedTrack,
} from '../repository/repo.utils'

export const ganttFormView = {
	TIMELINE_VIEW: 'TIMELINE_VIEW',
	DEPENDANCY_VIEW: 'DEPENDANCY_VIEW',
	SET_PARENT: 'SET_PARENT',
	ADD_ACTIVITY: 'ADD_ACTIVITY',
	SET_CHILD: 'SET_CHILD',
}
const GanttForm = ({
	isOpen,
	onClose,
	selectedActivityId,
	activity,
	isUpdateModel,
	onParentDateChange,
	activityList,
	parantActivity,
}) => {
	const [options, setOptions] = useState([])
	const [isError, setisError] = useState(false)
	const [orderFlag, setOrderFlag] = useState('')
	const [prereqTye, setprereqTye] = useState(1)
	const [isOverviewOpen, setOpen] = useState(false)
	const [viewType, setviewType] = useState('')
	const [participants, setparticipants] = useState([])
	const [externalUsers, setExternalUsers] = useState([])
	const [selectedUsers, setSelectedUsers] = useState([])
	const [disableEndDate, setDisableEndDate] = useState(false)
	const [disableStartDate, setDisableStartDate] = useState(false)
	const [selectedActivity, setselectedActivity] = useState('')
	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { activity: selectedAc, activityTypeDetails },
		dispatch,
	} = useSharedContext()
	const {
		state: { locale },
	} = useLanguageContext()

	const { mutate, isLoading } = useLoadActivityListV1()
	const { mutate: setActivityRef } = useSetActivityRef()
	const { mutate: updateActivityRef } = useUpdateActivityRef()
	const { mutate: loadChildActivities } = useLoadGanntChildActivity()
	const { mutate: loadAactivityParticipant } = useLoadActivityParticipant()
	const { mutate: externalGuestsAddMutate } = useExternalGuestsAdd()
	const { mutate: addParentActivity } = useAddParentActivity()
	const {
		isOpen: isTimelineOpen,
		onClose: timeLineClose,
		onOpen: timeLineOnOPen,
	} = useDisclosure()
	const {
		isOpen: parantIsOpen,
		onOpen: parantOnOpen,
		onClose: parantOnClose,
	} = useDisclosure()

	const {
		isOpen: isExtParticipantView,
		onOpen: onOpenExtParticipantView,
		onClose: onCloseExtParticipantView,
	} = useDisclosure()

	const {
		isOpen: isParticipantView,
		onOpen: opOpenParticipantView,
		onClose: OnCloseParticipantView,
	} = useDisclosure()

	const [ganttStates, setganttStates] = useState({
		StartDate: '',
		EndDate: '',
		Duration: '',
		progress: '',
	})

	const toLoadGanttData = data => {
		let finalData = []
		data.map(el => {
			finalData?.push(el)
			el?.child_workflows.map(val => {
				finalData.push(val)
			})
		})
		return finalData
	}

	useEffect(() => {
		if (
			activity?.prerequisite_list?.some(
				item =>
					item.activity_mapping_flag_is_prerequisite === 4 ||
					item.activity_mapping_flag_is_prerequisite === 1
			)
		) {
			setDisableStartDate(true)
		} else setDisableStartDate(false)
		if (
			activity?.prerequisite_list?.some(
				item =>
					item.activity_mapping_flag_is_prerequisite === 2 ||
					item.activity_mapping_flag_is_prerequisite === 3
			)
		) {
			setDisableEndDate(true)
		} else setDisableEndDate(false)
	}, [activity])

	useEffect(() => {
		const date1 = new Date(selectedActivityId.StartDate)
		const date2 = new Date(selectedActivityId.EndDate)
		setganttStates({
			...selectedActivityId,
			Duration: getDifferenceInDays(date1, date2),
			progress: selectedActivityId.Progress,
		})
		setselectedActivity(selectedAc)
		setOpen(false)
		if (!isOpen) return
		loadChildActivities(
			{
				parent_activity_id:
					activity?.parent_activity_id === 0
						? activity.activity_id
						: activity?.parent_activity_id,
				page_start: 0,
				page_limit: 30,
				startFrom: 0,
				sort_flag: 0,
				flag: 6,
				datetime_end: getCurrentDateUTC(),
				datetime_start: '1970-01-01 00:00:00',
			},
			{
				onSuccess: res => {
					// console.log(res.data.response)
					let finalData = toLoadGanttData(res.data.response)
					let data = finalData?.map(
						({
							activity_cuid_1,
							activity_cuid_2,
							activity_cuid_3,
							activity_id,
							activity_title,
							operating_asset_first_name,
							activity_type_category_id,
							activity_type_id,
							parent_activity_id,
						}) => ({
							details: {
								activity_cuid_1,
								activity_cuid_2,
								activity_cuid_3,
								operating_asset_first_name,
								activity_id,
								activity_type_category_id,
								activity_type_id,
								parent_activity_id,
							},
							label: activity_title,
							value: `${
								activity_id +
								'|' +
								activity_title +
								'|' +
								activity_type_category_id
							}`,
						})
					)

					setOptions([...data])
				},
				onError: async error => {
					setisError(true)
				},
			}
		)
	}, [isOpen])

	const handleChange = e => {
		// console.log(e.target.value);
		const { value, name } = e.target
		setganttStates(val => ({
			...val,
			[name]: value,
		}))
	}

	const onSubmit = () => {
		let finalObject = ganttStates
		finalObject.taskId = ganttStates.TaskID
		finalObject.startDate = ganttStates.StartDate
		finalObject.endDate = ganttStates.EndDate
		onParentDateChange({ editingFields: ganttStates })
		onClose()
	}

	useEffect(() => {
		const date1 = new Date(ganttStates.StartDate)
		const date2 = new Date(ganttStates.EndDate)
		setganttStates(val => ({
			...val,
			Duration: getDifferenceInDays(date1, date2),
		}))
	}, [ganttStates.StartDate, ganttStates.EndDate])

	const loadParticipants = useCallback(params => {
		loadAactivityParticipant(
			{ ...params },
			{
				onSuccess: async data => {
					setExternalUsers(
						data ? data.list.filter(u => u.asset_type_category_id === 45) : []
					)
					setparticipants(data ? data.list : [])
				},
			}
		)
	})

	const handleUserSelect = e => {
		let uIndex = selectedUsers.findIndex(u => u === e)
		let users = uIndex === -1 ? [...selectedUsers, e] : [...selectedUsers]
		setSelectedUsers(users)
	}

	const removeSelectedUser = e => {
		let uIndex = selectedUsers.findIndex(u => u.email === e)
		if (uIndex > -1) {
			selectedUsers.splice(uIndex, 1)
		}
		setSelectedUsers(selectedUsers)
		// setCount(count + 1);
	}

	const addGuests = () => {
		if (selectedUsers.length > 0) {
			addExternalGuests({
				emails: selectedUsers,
				workflow_activity_id: activity.activity_id,
				activity_type_id: activity.activity_type_id,
			})
		} else {
			alert('Enter email id')
		}
	}

	const addExternalGuests = useCallback(params => {
		onCloseExtParticipantView()
		externalGuestsAddMutate(
			{ ...params },
			{
				onSuccess: async data => {
					setSelectedUsers([])
					// loadDetails(activity);
					dispatch({
						type: sharedConst.REFRESH_TIMELINE,
					})
				},
				onError: async data => {
					setSelectedUsers([])
					// loadDetails(activity);
					dispatch({
						type: sharedConst.REFRESH_TIMELINE,
					})
				},
			}
		)
	})

	const onActivitySubmit = data => {
		if (data?.activity_id) {
			addParentActivity(
				{
					activity_id: data.activity_id,
					parent_activity_id: selectedActivity.activity_id,
				},
				{
					onSuccess: () => {
						dispatch({
							type: sharedConst.ACTVITY_SELECTED,
							payload: selectedActivity,
						})
						dispatch({
							type: sharedConst.REFRESH_GANNT_VIEW,
						})
						onClose()
					},
				}
			)
		} else {
			setOpen(false)
			setviewType('')
		}
	}

	const isParent = !!selectedActivityId?.subtasks?.length > 0
	return (
		<>
			<Drawer
				isOpen={isOpen}
				placement='right'
				onClose={onClose}
				size={
					isOverviewOpen &&
					(viewType === ganttFormView.TIMELINE_VIEW ||
						viewType === ganttFormView.ADD_ACTIVITY)
						? 'xl'
						: 'md'
				}
				// finalFocusRef={btnRef}
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>
						<Flex justifySelf='center' alignItems='center'>
							{selectedActivityId.TaskName}
						</Flex>
					</DrawerHeader>
					<Box width='95%' margin='0 auto' overflowX='auto'>
						<Flex justifyContent='flex-end'>
							<Button
								mr={1}
								borderRadius='md'
								size='sm'
								variant={
									viewType === ganttFormView.ADD_ACTIVITY ? 'solid' : 'outline'
								}
								colorScheme={localStorage.getItem('color')}
								bg={
									viewType === ganttFormView.ADD_ACTIVITY
										? localStorage.getItem('color')
										: ''
								}
								// isLoading={isLoading}
								onClick={() => {
									sectionDetailedTrack({
										category: GA_CATEGORY_GANTT,
										action: 'Gantt Form',
										label: 'Add New File',
									})
									setOpen(true)
									setviewType(ganttFormView.ADD_ACTIVITY)
								}}
							>
								{locale['Add new file']}
							</Button>
							<Button
								mr={1}
								borderRadius='md'
								size='sm'
								variant={
									viewType === ganttFormView.TIMELINE_VIEW ? 'solid' : 'outline'
								}
								colorScheme={localStorage.getItem('color')}
								bg={
									viewType === ganttFormView.TIMELINE_VIEW
										? localStorage.getItem('color')
										: ''
								}
								// isLoading={isLoading}
								onClick={() => {
									sectionDetailedTrack({
										category: GA_CATEGORY_GANTT,
										action: 'Gantt Form',
										label: 'Timeline View',
									})
									setOpen(true)
									setviewType(ganttFormView.TIMELINE_VIEW)
								}}
							>
								{locale['Timeline View']}
							</Button>
							<Button
								mr={1}
								borderRadius='md'
								size='sm'
								variant={parantIsOpen ? 'solid' : 'outline'}
								colorScheme={localStorage.getItem('color')}
								bg={parantIsOpen ? localStorage.getItem('color') : ''}
								// isLoading={isLoading}
								// disabled={isParent}
								onClick={() => {
									sectionDetailedTrack({
										category: GA_CATEGORY_GANTT,
										action: 'Gantt Form',
										label: 'Map Parent',
									})
									parantOnOpen()
									setviewType('')
									setOpen(false)
								}}
							>
								{locale['Map Parent']}
							</Button>
							<Button
								mr={1}
								borderRadius='md'
								size='sm'
								variant={parantIsOpen ? 'solid' : 'outline'}
								colorScheme={localStorage.getItem('color')}
								bg={parantIsOpen ? localStorage.getItem('color') : ''}
								// isLoading={isLoading}
								// disabled={isParent}
								onClick={() => {
									sectionDetailedTrack({
										category: GA_CATEGORY_GANTT,
										action: 'Gantt Form',
										label: 'Map Child',
									})
									parantOnOpen()
									setviewType(ganttFormView.SET_CHILD)
									setOpen(false)
								}}
							>
								{locale['Map Child']}
							</Button>
							<Button
								mr={1}
								borderRadius='md'
								size='sm'
								variant={
									viewType === ganttFormView.DEPENDANCY_VIEW
										? 'solid'
										: 'outline'
								}
								colorScheme={localStorage.getItem('color')}
								bg={
									viewType === ganttFormView.DEPENDANCY_VIEW
										? localStorage.getItem('color')
										: ''
								}
								isDisabled={isParent}
								// isLoading={isLoading}
								onClick={() => {
									sectionDetailedTrack({
										category: GA_CATEGORY_GANTT,
										action: 'Gantt Form',
										label: 'Map Dependency',
									})
									setOpen(true)
									setviewType(ganttFormView.DEPENDANCY_VIEW)
								}}
							>
								{locale['Map Dependency']}
							</Button>
						</Flex>
					</Box>
					{isTimelineOpen && (
						<WorkspacePanel
							isDashboard={true}
							onWidgetViewChange={() => {
								setviewType('')
							}}
						/>
					)}
					{isParticipantView && (
						<ParticipantView
							activity={activity}
							activityTypeDetails={activityTypeDetails}
							isOpen={isParticipantView}
							onClose={() => {
								OnCloseParticipantView()
								loadParticipants({ activity_id: activity.activity_id })
							}}
							addExternalUsers={() => {
								OnCloseParticipantView()
								onOpenExtParticipantView()
							}}
						/>
					)}
					{isExtParticipantView && (
						<AddExtParticipant
							selectedUsers={selectedUsers}
							handleUserSelect={handleUserSelect}
							removeSelectedUser={removeSelectedUser}
							isOpen={isExtParticipantView}
							onClose={() => {
								onCloseExtParticipantView()
							}}
							onSubmit={addGuests}
						/>
					)}
					<DrawerBody>
						{isOverviewOpen && [
							viewType !== ganttFormView.TIMELINE_VIEW ? (
								<IconButton
									bg='white'
									borderRadius='md'
									boxShadow='md'
									aria-label='Refresh'
									cursor='pointer'
									size='md'
									_focus={{
										bg: 'secondary',
									}}
									_hover={{
										bg: 'secondary',
									}}
									onClick={() => {
										sectionDetailedTrack({
											category: GA_CATEGORY_GANTT,
											action: 'Gantt Form',
											label: 'Go Back',
										})
										setOpen(false)
										setviewType('')
									}}
									icon={<Icon as={BiArrowBack} w={6} h={6} />}
								/>
							) : null,

							viewType === ganttFormView.TIMELINE_VIEW ? (
								<Box
									w='full'
									background='white'
									borderRadius='md'
									minH={isOpen ? 'calc(100vh - 280px)' : 'calc(100vh - 180px)'}
								>
									<WorkspacePanel
										isDashboard={true}
										onWidgetViewChange={() => {
											setOpen(false)
											setviewType('')
										}}
									/>
								</Box>
							) : viewType === ganttFormView.ADD_ACTIVITY ? (
								<OriginFormPanel
									onClose={data => onActivitySubmit(data)}
									from={'gantt'}
								/>
							) : (
								<ReferenceList
									className={`reference-files-tab-prerequisites-container`}
									selectedActivity={activity}
									parantActivity={parantActivity}
									type={'INTERNAL'}
									filter={{
										label: locale['All'],
										Value: 'All',
									}}
									listType={'prerequisites'}
								/>
							),
						]}

						{!isOverviewOpen
							? [
									<Box mb='4'>
										<Text mb='5px'>{locale['Start Date']}</Text>
										<Input
											type='date'
											value={moment(ganttStates.StartDate).format('YYYY-MM-DD')}
											onChange={handleChange}
											placeholder={locale['Start Date']}
											size='md'
											name='StartDate'
											isDisabled={isParent || disableStartDate}
											max={
												disableEndDate &&
												moment(ganttStates.EndDate).format('YYYY-MM-DD')
											}
										/>
									</Box>,

									<Box mb='4'>
										<Text mb='5px'>{locale['End Date']}</Text>
										<Input
											type='date'
											value={moment(ganttStates.EndDate).format('YYYY-MM-DD')}
											onChange={handleChange}
											placeholder='End Date'
											size='md'
											name='EndDate'
											isDisabled={isParent || disableEndDate}
											min={
												disableStartDate &&
												moment(ganttStates.StartDate).format('YYYY-MM-DD')
											}
										/>
									</Box>,

									<Box mb='4'>
										<Text mb='5px'>{locale['Duration']}</Text>
										<Input
											type='number'
											value={ganttStates.Duration}
											onChange={handleChange}
											placeholder='Duration'
											size='md'
											isDisabled={true}
											name='Duration'
										/>
									</Box>,

									<Box mb='4'>
										<Text mb='5px'>{locale['Progress']}</Text>
										<Input
											type='number'
											value={ganttStates.progress}
											onChange={handleChange}
											placeholder='progress'
											size='md'
											isDisabled={isParent}
											name='progress'
										/>
									</Box>,
							  ]
							: null}
					</DrawerBody>

					{!isOverviewOpen && (
						<DrawerFooter>
							<Button
								variant='outline'
								mr={3}
								onClick={e => {
									sectionDetailedTrack({
										category: GA_CATEGORY_GANTT,
										action: 'Gantt Form',
										label: 'Cancel',
									})
									onClose(e)
								}}
							>
								{locale['Cancel']}
							</Button>
							<Button
								colorScheme={localStorage.getItem('color')}
								bg={localStorage.getItem('color')}
								onClick={() => {
									sectionDetailedTrack({
										category: GA_CATEGORY_GANTT,
										action: 'Gantt Form',
										label: 'Save',
									})
									onSubmit()
								}}
							>
								{locale['Save']}
							</Button>
						</DrawerFooter>
					)}
				</DrawerContent>
				<SetParantActivityChd
					isOpen={parantIsOpen}
					onOpen={parantOnOpen}
					onClose={parantOnClose}
					activity={activity}
					viewType={viewType}
					activityList={activityList}
				/>
			</Drawer>
		</>
	)
}

export default GanttForm
