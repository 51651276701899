/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {
  Box,
  Divider,
  HStack,
  Icon,
  IconButton,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  useDisclosure,
  Text,
  VStack,
  TagLabel,
  Tag,
} from '@chakra-ui/react'
import Card from '../../components/card'
import { FiCopy } from 'react-icons/fi'
import CustomMenu from '../../components/menu'
import {
  MdArrowDropDown,
  MdArrowDropUp,
  MdDateRange,
  MdRefresh,
  MdTimelapse,
  MdAccessTime,
  MdSort,
} from 'react-icons/md'
import { toSnakeCase } from '../../utils/common.util'
import { Collapse } from '@chakra-ui/transition'
import { useAuthContext } from '../../context/auth.context'
import { format } from 'date-fns'
import {
  GA_CATEGORY_GANTT,
  sectionDetailedTrack,
} from '../repository/repo.utils'
import { useLanguageContext } from '../../context/locale.context'
const GanttActivityHeader = ({
  activity,
  toLoadChildActivity,
  filterData,
  filterTypes,
}) => {
  const {
    state: { authData },
  } = useAuthContext()
  const {
    state: { locale },
  } = useLanguageContext()
  const { isOpen, onToggle } = useDisclosure()
  const [disableEditLead, setDisableEditLead] = useState(true)
  const [disableRollbackStatus, setDisableRollbackStatus] = useState(true)
  const [currentLead, setCurrentLead] = useState()
  const [currentStatus, setCurrentStatus] = useState()
  const [selectedFilter, setselectedFilter] = useState(filterTypes[2])
  const { asset_id, asset_flag_super_admin, organization_ai_bot_enabled } =
    authData

  const timeColor = datetime => {
    let isDelayed =
      new Date(datetime.split(' ').join('T')).setHours(0, 0, 0, 0) <
      new Date().setHours(0, 0, 0, 0)
    if (!!isDelayed) {
      return 'red'
    } else {
      return ''
    }
  }

  useEffect(() => {
    if (!!activity) {
      setTimeout(() => {
        // setworkspaceMode(WORKSPACE_MODE.TEAM_COLLABORATION);
        setDisableEditLead(true)
        setDisableRollbackStatus(true)
        setCurrentLead({
          value: activity_lead_operating_asset_id,
          label: activity_lead_operating_asset_first_name,
        })
        setCurrentStatus({
          value: activity.activity_status_id,
          label: activity.activity_status_name,
        })
      }, 1000)
    }
  }, [activity])
  const {
    activity_lead_operating_asset_first_name,
    operating_asset_first_name,
    activity_type_category_name,
    activity_type_category_id,
    activity_type_name,
    activity_status_name,
    activity_cuid_1,
    activity_status_id,
    activity_cuid_2,
    activity_cuid_3,
    activity_datetime_created,
    activity_datetime_start_expected,
    activity_datetime_end_deferred,
    activity_datetime_end_status,
    participant_list,
    activity_workflow_completion_percentage: completion,
    activity_type_id,
    activity_lead_operating_asset_id,
  } = activity
  return (
    <Card minH="40px" maxH="250px" className={`activity-container`}>
      <HStack
        alignItems="center"
        className={`activity-${toSnakeCase(activity?.activity_title)}`}
      >
        {/* <IconButton
                    bg="white"
                    borderRadius="md"
                    boxShadow="md"
                    aria-label="Refresh"
                    cursor="pointer"
                    size="md"
                    _focus={{
                        bg: 'secondary',
                    }}
                    _hover={{
                        bg: 'secondary',
                    }}
                    // onClick={() =>
                    //     // onWidgetViewChange(WIDGET_WORKSPACE_MODE.WIDGET_DATA)
                    // }
                    icon={<Icon as={BiArrowBack} w={6} h={6} />}
                /> */}

        <Text
          flex={1}
          fontSize="md"
          onClick={() => {
            sectionDetailedTrack({
              category: GA_CATEGORY_GANTT,
              action: 'Gantt Activity Header',
              label: 'Expand',
            })
            onToggle()
          }}
          color="black"
          textTransform="capitalize"
          className={`activity-name-text-${toSnakeCase(
            activity?.activity_title
          )}`}
        >
          {activity?.activity_title}
        </Text>

        <Icon
          className={`activity-arrow-icon-${toSnakeCase(
            activity?.activity_title
          )}`}
          cursor="pointer"
          as={!isOpen ? MdArrowDropDown : MdArrowDropUp}
          w={7}
          h={7}
          onClick={() => {
            sectionDetailedTrack({
              category: GA_CATEGORY_GANTT,
              action: 'Gantt Activity Header',
              label: 'Expand',
            })
            onToggle()
          }}
        />
        <IconButton
          className={`activity-reload-button-${toSnakeCase(
            activity?.activity_title
          )} ${isOpen ? 'expand' : ''}`}
          bg="white"
          borderRadius="md"
          boxShadow="sm"
          aria-label="Refresh"
          size="sm"
          _focus={{
            bg: 'secondary',
          }}
          _hover={{
            bg: 'secondary',
          }}
          icon={<Icon as={MdRefresh} w={6} h={6} />}
          // disabled={activityParticipantId === 152}
          onClick={() => {
            sectionDetailedTrack({
              category: GA_CATEGORY_GANTT,
              action: 'Gantt Activity Header',
              label: 'Load Child Activity',
            })
            toLoadChildActivity(0)
          }}
        />

        <CustomMenu
          className={`activity-menu-${toSnakeCase(activity?.activity_title)} ${
            isOpen ? 'expand' : ''
          }`}
          icon={MdSort}
          bg="white"
          borderRadius="md"
          boxShadow="sm"
          aria-label="More"
          size="sm"
          _focus={{
            bg: 'secondary',
          }}
          _hover={{
            bg: 'secondary',
          }}
          iconProps={{
            position: 'absolute',
            right: '20px',
            size: 'sm',
          }}
          optionList={filterTypes}
          selected={selectedFilter}
          onSelect={item => {
            setselectedFilter(item)
            filterData(item)
          }}
        />
      </HStack>
      <Collapse
        in={isOpen}
        animateOpacity
        className={`activity-body-${toSnakeCase(activity?.activity_title)} ${
          isOpen ? 'expand' : ''
        }`}
      >
        <Divider py={1} />
        <Box px={1} mt={2} shadow="md">
          <HStack alignItems="flex-start" justifyContent="flex-start">
            <VStack flex={1} alignItems="flex-start">
              <HStack
                alignItems="flex-start"
                justifyContent="flex-start"
                mb={1}
              >
                {activity_status_name && (
                  <>
                    <Tag
                      className={`activity-status-tag-${toSnakeCase(
                        activity_status_name
                      )}`}
                      variant="outline"
                      borderRadius="full"
                      colorScheme={localStorage.getItem('color')}
                      size="md"
                      fontSize="12px"
                      mb={1}
                    >
                      <TagLabel maxW="full">{activity_status_name}</TagLabel>
                    </Tag>
                    {/* <Box>
                                            <Select
                                                value={currentStatus}
                                                options={previousStatus}
                                                onChange={e => {
                                                    setCurrentStatus(e);
                                                }}
                                                styles={customPillStyles}
                                                isSearchable={false}
                                                isDisabled={disableRollbackStatus}
                                            />
                                        </Box> */}
                    {/* {activityTypeDetails?.activity_type_arp_lead_setting_enabled ==
                                            1 && (
                                                <Box>
                                                    <IconButton
                                                        bg="white"
                                                        ml={2}
                                                        aria-label="Rollback Status"
                                                        size="md"
                                                        _focus={{
                                                            bg: 'secondary',
                                                        }}
                                                        _hover={{
                                                            bg: 'secondary',
                                                        }}
                                                        onClick={rollbackStatus}
                                                        icon={
                                                            disableRollbackStatus ? (
                                                                <Icon as={MdHistory} w={5} h={5} />
                                                            ) : (
                                                                <Icon as={MdDone} w={5} h={5} />
                                                            )
                                                        }
                                                    />
                                                </Box>
                                            )} */}
                  </>
                )}
              </HStack>
            </VStack>
          </HStack>
          <HStack spacing={1} alignItems="flex-start">
            <VStack flex={1} alignItems="flex-start">
              <HStack alignItems="flex-start" justifyContent="flex-start">
                <Text
                  fontSize="12px"
                  mb={1}
                  className={`activity-type-${toSnakeCase(
                    activity_type_category_name
                  )}`}
                >
                  {activity_type_category_name} :{' '}
                  <Text
                    as="span"
                    fontWeight="500"
                    className={`activity-type-${toSnakeCase(
                      activity_type_name
                    )}`}
                  >
                    {activity_type_name}
                  </Text>
                </Text>
                {!!completion && (
                  <HStack
                    spacing={2}
                    flex={1}
                    className={`activity-completion-container`}
                  >
                    <Slider
                      className={`activity-completion-slider`}
                      aria-label="slider-ex-2"
                      isDisabled={true}
                      // defaultValue={completion || 0}
                      value={completion}
                      w="150px"
                    >
                      <SliderTrack
                        height={2}
                        borderRadius="full"
                        className={`activity-completion-track`}
                      >
                        <SliderFilledTrack
                          bg="orange.600"
                          className={`activity-completion-fill`}
                        />
                      </SliderTrack>
                    </Slider>
                    <Text
                      className={`activity-completion-text-percentage`}
                      color={localStorage.getItem('color')}
                    >
                      {`${completion}%` || 0}
                    </Text>
                  </HStack>
                )}
              </HStack>
            </VStack>
            <VStack
              className={`activity-creation-details`}
              w="400px"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Text fontSize="12px" className={`activity-creator-text`}>
                {locale['Creator']} :{' '}
                <Text
                  as="span"
                  fontWeight="500"
                  className={`activity-creator-text-${toSnakeCase(
                    operating_asset_first_name
                  )}`}
                >
                  {operating_asset_first_name}
                </Text>
              </Text>
              {activity_lead_operating_asset_first_name && (
                <Text fontSize="12px" className={`activity-lead-text`}>
                  {locale['Lead']} :{' '}
                  {/* <Select
                                        value={currentLead}
                                        options={possibleLead}
                                        onChange={e => {
                                            setCurrentLead(e);
                                        }}
                                        styles={customStyles}
                                        isSearchable={false}
                                        isDisabled={disableEditLead}
                                    /> */}
                  {/* {activityTypeDetails?.activity_type_arp_lead_setting_enabled ==
                                        1 && (
                                            <IconButton
                                                bg="white"
                                                ml={2}
                                                aria-label="Edit Current Lead"
                                                size="md"
                                                _focus={{
                                                    bg: 'secondary',
                                                }}
                                                _hover={{
                                                    bg: 'secondary',
                                                }}
                                                icon={
                                                    disableEditLead ? (
                                                        <Icon as={MdEdit} w={5} h={5} />
                                                    ) : (
                                                        <Icon as={MdDone} w={5} h={5} />
                                                    )
                                                }
                                                onClick={changeLeadByManager}
                                            />
                                        )} */}
                </Text>
              )}
            </VStack>
            <VStack
              justifyContent="flex-start"
              alignItems="flex-start"
              className={`activity-time-log-container`}
            >
              <Text
                fontSize="12px"
                className={`activity-time-text-${activity_datetime_created}`}
              >
                <Icon
                  as={MdDateRange}
                  w={4}
                  h={4}
                  className={`activity-date-time-icon`}
                />
                {!!activity_datetime_created
                  ? format(
                      new Date(activity_datetime_created.split(' ').join('T')),
                      'dd MMM yyyy'
                    )
                  : !!activity_datetime_start_expected
                  ? format(
                      new Date(
                        activity_datetime_start_expected.split(' ').join('T')
                      ),
                      'dd MMM yyy'
                    )
                  : null}
              </Text>
              {!!activity_datetime_end_deferred && (
                <Text
                  fontSize="12px"
                  color={timeColor(activity_datetime_end_deferred)}
                  className={`activity-end-time-text-${activity_datetime_end_deferred}`}
                >
                  <Icon
                    as={MdTimelapse}
                    w={4}
                    h={4}
                    className={`activity-time-laps-icon`}
                  />
                  {activity_datetime_end_deferred.includes('date') ||
                  activity_datetime_end_deferred.includes('0000-00-00')
                    ? activity_datetime_end_deferred
                    : format(
                        new Date(
                          activity_datetime_end_deferred.split(' ').join('T')
                        ),
                        'dd MMM yyyy'
                      )}
                </Text>
              )}
              {!!activity_datetime_end_status &&
                organization_ai_bot_enabled === 1 && (
                  <Text
                    fontSize="12px"
                    className={`activity-time-text-${activity_datetime_end_deferred}`}
                    color={timeColor(activity_datetime_end_status)}
                  >
                    <Icon
                      as={MdAccessTime}
                      w={4}
                      h={4}
                      className={`activity-date-time-icon`}
                    />
                    {!!activity_datetime_end_status
                      ? format(
                          new Date(
                            activity_datetime_end_status.split(' ').join('T')
                          ),
                          'dd MMM yyyy'
                        )
                      : !!activity_datetime_end_status
                      ? format(
                          new Date(
                            activity_datetime_end_status.split(' ').join('T')
                          ),
                          'dd MMM yyy'
                        )
                      : null}
                  </Text>
                )}
            </VStack>
          </HStack>
          <HStack
            className={`activity-cuid-container`}
            justifyContent="space-between"
            mt={2}
            alignItems="center"
            spacing={4}
          >
            {activity_cuid_1 && (
              <Text mb={1} fontSize="12px" className={`activity-CUID-1-text`}>
                {locale['CUID 1']} :{' '}
                <Text
                  as="span"
                  fontWeight="500"
                  className={`activity-CUID-1-text-${activity_cuid_1}`}
                >
                  {activity_cuid_1}
                </Text>{' '}
                <Icon
                  className={`activity-CUID-1-copy-icon`}
                  as={FiCopy}
                  cursor="pointer"
                  onClick={() => {
                    sectionDetailedTrack({
                      category: GA_CATEGORY_GANTT,
                      action: 'Gantt Activity Header',
                      label: 'CUID 1 Copied',
                    })
                    navigator.clipboard.writeText(activity_cuid_1)
                  }}
                  w={3}
                  h={3}
                />{' '}
              </Text>
            )}
            {activity_cuid_2 && (
              <Text mb={1} fontSize="12px" className={`activity-CUID-2-text`}>
                {locale['CUID 2']} :{' '}
                <Text
                  as="span"
                  fontWeight="500"
                  className={`activity-CUID-2-text-${activity_cuid_2}`}
                >
                  {activity_cuid_2}
                </Text>{' '}
                <Icon
                  className={`activity-CUID-2-copy-icon`}
                  as={FiCopy}
                  cursor="pointer"
                  onClick={() => {
                    sectionDetailedTrack({
                      category: GA_CATEGORY_GANTT,
                      action: 'Gantt Activity Header',
                      label: 'CUID 2 Copied',
                    })
                    navigator.clipboard.writeText(activity_cuid_2)
                  }}
                  w={3}
                  h={3}
                />{' '}
              </Text>
            )}
            {activity_cuid_3 && (
              <Text mb={1} className={`activity-CUID-3-text`} fontSize="12px">
                {locale['CUID 3']} :{' '}
                <Text
                  as="span"
                  fontWeight="500"
                  className={`activity-CUID-3-text-${activity_cuid_3}`}
                >
                  {activity_cuid_3}
                </Text>{' '}
                <Icon
                  as={FiCopy}
                  cursor="pointer"
                  onClick={() => {
                    sectionDetailedTrack({
                      category: GA_CATEGORY_GANTT,
                      action: 'Gantt Activity Header',
                      label: 'CUID 3 Copied',
                    })
                    navigator.clipboard.writeText(activity_cuid_3)
                  }}
                  w={3}
                  h={3}
                />{' '}
              </Text>
            )}
            {/*  {participants && !!participants.length && (
                <AvatarGroup
                  justifySelf="flex-end"
                  size="sm"
                  max={3}
                  spacing="-5px"
                  onClick={onOpen}
                  cursor="pointer"
                >
                  {(participants || []).map(p => {
                    return (
                      <Avatar
                        key={p.asset_id}
                        name={p.operating_asset_first_name}
                      />
                    );
                  })}
                  <Avatar name="+" key="new" bg="secondary" color={localStorage.getItem('color')} />
                </AvatarGroup>
              )} */}
          </HStack>
        </Box>
      </Collapse>
    </Card>
  )
}

export default GanttActivityHeader
